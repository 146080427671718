import React, { useContext, useEffect, useState } from 'react';
import ScreenWrapper from '../components/ScreenWrapper';
import Header from '../components/Header';
import { Plus, RefreshCw } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/authContext';
import { getProject } from '../request/request';
import { formatCurrency } from '../utils/helper';
import BottomBar from '../components/BottomBar';

function HomeScreen() {

    const [state] = useContext(AuthContext);
    const currentUser = state.user;
    const [projectData, setProjectData] = useState([]);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const fetchProjectList = async () => {
        setIsLoading(true);
        try {
            const projectDataResponse = await getProject(currentUser.role_id, currentUser.user_id);
            if (projectDataResponse.res_code == 200) {
                setProjectData(projectDataResponse.data);
            } else {
                console.error('Failed to fetch projects:', projectDataResponse.message);
            }
        } catch (error) {
            console.error('Error fetching project list:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchMoreData = async () => {
        if (isLoadingMore) return; // Avoid duplicate calls

        setIsLoadingMore(true);
        try {
            const response = await getProject(currentUser.role_id, currentUser.user_id, page + 1);
            if (response.res_code == 200) {
                setProjectData((prevData) => [...prevData, ...response.data]);
                setPage((prevPage) => prevPage + 1);
            } else {
                console.error('Error fetching more projects:', response.message);
            }
        } catch (error) {
            console.error('Error fetching more project data:', error);
        } finally {
            setIsLoadingMore(false);
        }
    };
    // Fetch projects when the component is mounted or currentUser changes
    useEffect(() => {
        if (currentUser) {
            fetchProjectList();
        }
    }, [currentUser]);

    const refreshProject = async () => {
        await fetchProjectList();
    };

    const isAuthorized = currentUser && currentUser.role_id === 1;

    const renderItem = (item) => (
        <div
            className="bg-white p-3 rounded-2xl mb-3 shadow-sm"
            key={item.project_id}
            onClick={() => navigate('/view-project', { state: { project: item } })}
        >
            <div className="flex items-center justify-between">
                <span className="font-bold">{item.master_project_name}</span>
                <div className="p-1 px-3 bg-blue-400 text-white rounded-full shadow-sm">
                    <span className="font-bold">{item.project_flag}</span>
                </div>
            </div>
            <div className="flex items-center justify-between">
                <span className="font-bold">Project Id : project-{item.project_id}</span>
            </div>
            <div className="flex items-center justify-between">
                <span className="font-bold">Payment Requested by : {item.name}</span>
            </div>
            <div className="items-center justify-between">
                <span className="font-bold">Stage of work:</span>
                <div className="px-3">
                    <span className="text-sm font-bold">
                        Nature of work: </span>
                    <span className="text-sm">{item.project_name}</span>
                </div>
                <div className="px-3">
                    <span className="text-sm font-bold">
                        Description of work: </span>
                    <span className="text-sm">{item.project_description}</span>
                </div>
            </div>
            <div className="flex items-center justify-between">
                <span className="text-sm"><b>Create Date & Time</b>: {item.create_date} {item.create_time}</span>
            </div>
            <div className="flex items-center justify-between">
                <span className="text-sm"><b>Estimated cost:</b>: {formatCurrency(Number(item.estimated_cast))}</span>
            </div>
        </div>
    );

    return (
        <ScreenWrapper>
            <div className='flex-1 flex flex-col'>
                <Header />
                <div className="mb-16 flex-1 flex flex-col items-center justify-center">
                    <div className="bg-white rounded-xl m-4 mb-4 p-4 flex items-center justify-center"> {/* Center image container */}
                        <img src="/assets/3371471.jpg" alt="Project" className="w-60 h-60" />
                    </div>
                    <div className="px-4 w-full"> {/* Ensure the text container takes full width */}
                        <div className="flex flex-row justify-between items-center mb-4"> {/* Add margin-bottom for spacing */}
                            <h6 className="text-lg font-semibold">Payment Requests</h6>
                            <div className="flex space-x-2"> {/* Align buttons horizontally with space between */}
                                <button
                                    className="p-2 px-3 border border-gray-200 rounded-full shadow-sm bg-primary flex items-center"
                                >
                                    <RefreshCw height={20} width={20} stroke={'white'} />
                                </button>
                                {isAuthorized && (<>
                                    <button
                                        className="flex items-center p-2 px-3 border border-gray-200 rounded-full shadow-sm bg-primary"
                                        onClick={() => navigate('/add-project')}
                                    >
                                        <Plus height={20} width={20} stroke={'white'} />
                                        <h6 className="font-bold text-sm text-white ml-2">New</h6>
                                    </button></>)}
                            </div>
                        </div>

                        {isLoading ? (
                            <div className="loader">Loading Payment Requests...</div>
                        ) : (
                            <div
                                className="project-list"
                                style={{ overflowY: 'auto', height: '70vh' }}
                                onScroll={(e) => {
                                    const { scrollTop, scrollHeight, clientHeight } = e.target;
                                    if (scrollTop + clientHeight >= scrollHeight - 10) {
                                        fetchMoreData();
                                    }
                                }}
                            >
                                {projectData.length > 0 ? (
                                    projectData.map(renderItem)
                                ) : (
                                    <h5>Empty</h5>
                                )}

                                {/* Footer Loader */}
                                {isLoadingMore && (
                                    <div className="loader-footer">
                                        <span>Loading more projects...</span>
                                    </div>
                                )}
                            </div>
                        )}

                        {/* {loader ? (
                            <div className="flex justify-center items-center mt-4">
                                <span className={`font-bold text-red-400 py-16`}>Loading Payment Request...</span>
                            </div>
                        ) : (
                            <>
                                {projectData.length == 0 ? <h5>Empty</h5> :
                                    projectData.map(item => (
                                        <div
                                            className="bg-white p-3 rounded-2xl mb-3 shadow-sm"
                                            key={item.project_id}
                                            onClick={() => navigate('/view-project', { state: { project: item } })}
                                        >
                                            <div className="flex items-center justify-between">
                                                <span className="font-bold">{item.master_project_name}</span>
                                                <div className="p-1 px-3 bg-blue-400 text-white rounded-full shadow-sm">
                                                    <span className="font-bold">{item.project_flag}</span>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <span className="font-bold">Project Id : project-{item.project_id}</span>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <span className="font-bold">Payment Requested by : {item.name}</span>
                                            </div>
                                            <div className="items-center justify-between">
                                                <span className="font-bold">Stage of work:</span>
                                                <div className="px-3">
                                                    <span className="text-sm font-bold">
                                                        Nature of work: </span>
                                                    <span className="text-sm">{item.project_name}</span>
                                                </div>
                                                <div className="px-3">
                                                    <span className="text-sm font-bold">
                                                        Description of work: </span>
                                                    <span className="text-sm">{item.project_description}</span>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <span className="text-sm"><b>Create Date & Time</b>: {item.create_date} {item.create_time}</span>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <span className="text-sm"><b>Estimated cost:</b>: {formatCurrency(Number(item.estimated_cast))}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </>
                        )} */}
                    </div>
                </div>
                <BottomBar />
            </div>
        </ScreenWrapper>
    );
}

export default HomeScreen;
